@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
	scroll-behavior: smooth;
	--blackColor: #1C1C1E;
	--darkBlackColor: #202020;
	--whiteColor: #fff;
	--darkGolderColor: #665837;
	--layoutTheme: #191919;
	--layoutSiderMenu: #23211e;
	--tomatoRed: #f3e5ce;
	--parrotGreen: #00D539;
	--darkBlueTheme: #144665;
	--lightBlueTheme: #f0f5ff;
	--defaultThemeColor: #0146D3;
	--defaultFontsFamilyPopins: "Poppins", serif;
	--defaultColorOne: #f3e5ce;
	--defaultColorTwo: #f5f3f0;
	--defaultGradientColor: linear-gradient(to top, var(--defaultColorTwo), var(--defaultColorOne));
	--defaultGradientColorReverse: linear-gradient(to top, var(--defaultColorOne), var(--defaultColorTwo));
}

*,
body {
	box-sizing: border-box;
	font-family: var(--defaultFontsFamilyPopins) !important;
}

.poppins-thin {
	font-family: "Poppins", serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: "Poppins", serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: "Poppins", serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: "Poppins", serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: "Poppins", serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: "Poppins", serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: "Poppins", serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: "Poppins", serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: "Poppins", serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: "Poppins", serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: "Poppins", serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: "Poppins", serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: "Poppins", serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: "Poppins", serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: "Poppins", serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: "Poppins", serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: "Poppins", serif;
	font-weight: 900;
	font-style: italic;
}


/* Side Scroller */
::-webkit-scrollbar {
	height: 9px;
	width: 8px
}

::-webkit-scrollbar-track {
	background: #d9dcd7
}

::-webkit-scrollbar-thumb {
	background: var(--blackColor);
	border-radius: 3px
}

::-webkit-scrollbar-thumb:hover {
	background: #337ec3
}


p {
	font-weight: 400;
	font-size: 14px;
	color: var(--darkBlackColor);
	text-align: center;
}

h5 {
	font-family: var(--defaultFontsFamilyPopins) !important;
	font-size: 20px !important;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}

/* =========================================================== ANTD GLOBAL =========================================================== */
.input__ {
	background-color: var(--defaultwhitecolor);
	box-shadow: 0px 2px 10px 0px #0000000D;
	height: 3rem;
	border: none;
}

.link_style {
	color: var(--defaultThemeColor);
	text-decoration: none;
	font-weight: 500;
}

label {
	font-size: 14px;
	font-weight: 500 !important;
	margin-bottom: 10px;
}

.ant-form-item-label>label {
	font-size: 14px;
	font-weight: 500 !important;
}

.ant-layout {
	font-family: var(--defaultFontsFamilyPopins) !important;
	font-weight: 600;
}

li.ant-menu-item.ant-menu-item-only-child {
	border-radius: 0 !important;
	margin: 0 0 2px !important;
	padding: 1.5rem;
	background: #f3f7ff;
	font-size: 12px;
}
.ant-modal .anticon{
	background: var(--defaultThemeColor);
	color: white;
	padding: 5px;
	border-radius: 44px;
}

.ant-table-thead>tr>th {
	background: #154766 !important;
	text-align: left !important;
}

.ant-table-wrapper .ant-table .ant-table-header {
	border-radius: 0 !important;
}

.headingStyle {
	opacity: 0;
	animation: fadeIn 3.5s ease-out forwards;
	-webkit-animation: fadeIn 3.5s ease-out forwards;
}

.img-thumbnail {
	border: none !important;
}
.ant-modal-root .ant-modal-mask {
/* background: linear-gradient(107.56deg, #0046D3 0%, #851B83 100%); */
}

.ant-input-outlined[disabled]{
	background: white;
	opacity: 1;
	color: #000;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
	background: var(--whiteColor);
}


li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
	color: var(--whiteColor) !important;
	background: var(--defaultThemeColor);
	font-weight: 400 !important;
	border-left: 7px solid var(--defaultThemeColor);
	/* padding-left: 18px !important; */
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .sideIcon {
	color: var(--whiteColor) !important;
}

.selectClass .ant-select-selector {
	border: none !important;
	min-height: 2.5rem;
	background: rgba(128, 128, 128, 0.14) !important;
	box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
}

.ant-picker-suffix {
	color: var(--defaultThemeColor) !important;
}

.sideIcon {
	color: var(--defaultThemeColor);
	font-size: 20px;
}

.ant-menu-submenu-title .sideIcon {
	color: var(--defaultThemeColor) !important;
	font-size: 20px !important; 
}

.ant-menu-submenu-title {
	padding: 24px 28px !important;
	font-size: 12px;
	font-weight: 500;
}

.ant-menu-submenu {
	background-color: var(--lightBlueTheme) !important;

}

li.ant-menu-item.ant-menu-item-only-child {
	padding-left: 24px !important;
}

.ant-layout .ant-layout-sider {
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.textArea {
	box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
	border: none;
	background: rgba(128, 128, 128, 0.14);
}

.jodit-placeholder {
	display: none !important
}

.ant-menu-inline .ant-menu-item {
	height: 40px;
	line-height: 25px;
	list-style-position: inside;
	list-style-type: disc;
	font-weight: 400;
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
	-ms-border-radius: 0px !important;
	-o-border-radius: 0px !important;
}

.ant-dropdown-menu-item {
	color: #fff !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu .ant-menu-title-content {
	transition: color 0.3s;
	align-items: center;
	display: flex;
}

.ant-layout-sider-trigger {
	display: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
	border: 1px dashed var(--defaultGradientColor);
}

.ant-table-thead .ant-table-cell {
	background-color: #EEF4FF !important;
	color: var(--darkBlackColor) !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
	text-align: left !important;
	font-weight: 500;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child> :first-child {
	border-start-start-radius: 0px !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child> :last-child {
	border-start-end-radius: 0px !important;
}


.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-container:not(.jodit_inline) {
	border-radius: 3px;
}

.jodit-container:not(.jodit_inline) {
	border: 1px solid var(--defaultGradientColor) !important;
	border-radius: 3px;
}

.jodit-status-bar {
	display: none !important;
}

.ant-breadcrumb-separator {
	font-size: 1.2rem;
}

.ant-input::placeholder {
	color: rgb(126, 124, 124) !important;
}

span.ant-select-selection-item {
	display: flex;
	text-align: center;
	align-items: center;
	/* color: var(--whiteColor); */
}


.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
	border-width: 1px;
	border-style: solid;
	border-color: #ff4d4f;
}

.ant-input-affix-wrapper>input.ant-input {
	padding: 0;
	font-size: inherit;
	border: none;
	border-radius: 0;
	outline: none;
}

.ant-picker-outlined {
	border-width: 1px;
	border-style: solid;
	border-color: var(--defaultThemeColor);
}

.ant-select-selector {
	border: 1px solid var(--defaultThemeColor) !important;
	min-height: 2.5rem;
}

.ant-select-single {
	min-height: 2.5rem;
	text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
	/* width: 8rem !important;
  height: 8rem !important; */
	margin-inline-end: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	border: 1px dashed var(--defaultThemeColor);
	border-radius: 8px;
	cursor: pointer;
	transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
	/* width: 8rem !important;
  height: 8rem !important; */
}

.my_upload .ant-tooltip-inner {
	display: none;
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled):hover {
	/* border: 1px solid var(--defaultThemeColor) !important; */
}

.ant-picker-outlined:hover {
	/* border: 1px solid var(--defaultThemeColor) !important; */
}

.rowsProgram {
	align-items: inherit !important;
}


.ant-switch {
	background-color: var(--defaultColorTwo) !important;
}

.ant-table-wrapper .ant-table-cell-fix-left {
	background: inherit;
}

.ant-switch.ant-switch-checked {
	background: #00AE2F !important;
}

.ant-switch.ant-switch-unchecked {
	background: #00AE2F !important;
}

.ant-picker.ant-picker-range {
	width: 100%;
}

.ant-pagination .ant-pagination-item {
	border: 1px solid #f0f5ff !important;
}

.ant-pagination-prev {
	font-weight: 400 !important;
}

.ant-pagination-next {
	font-weight: 400 !important;
}

.ant-pagination .ant-pagination-item-active {
	font-weight: 600;
	background: var(--lightBlueTheme);
	border-color: none !important;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.ant-divider-horizontal {
	margin: 24px 0;
	background: var(--defaultGradientColor);
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
	padding: 4px;
	list-style-type: none;
	background-clip: padding-box;
	border-radius: 8px;
	outline: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
	margin-bottom: 3px !important;
	color: var(--blackColor) !important;
	font-size: 1rem;
	background: var(--defaultColorTwo) !important;
}

.ant-dropdown {
	min-width: 132.507px !important;
}

.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding-inline: 15px;
	padding-block: 0;
	font-size: 14px;
	line-height: 30px;
	background: transparent !important;
	border: transparent !important;
}

.ant-tooltip.css-dev-only-do-not-override-1rqnfsa.ant-tooltip-placement-top {
	display: none;
}

.highcharts-button {
	display: none
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
	border-color: var(--defaultGradientColor);
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
	background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: var(--defaultGradientColor) !important;
}


.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: var(--defaultThemeColor) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background: var(--defaultGradientColor) !important;
	border-color: var(--defaultGradientColor);
}

.ant-checkbox .ant-checkbox-inner {
	border: 1px solid var(--defaultGradientColor);
}

.award.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {

	border: none !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead>tr>td {
	border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
	background: #fff;
	border-radius: 8px !important;
	-webkit-border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	-ms-border-radius: 8px !important;
	-o-border-radius: 8px !important;
}

/* Chart */
text.highcharts-credits {
	display: none;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
	border-bottom: transparent !important;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}

g.highcharts-legend-item.highcharts-area-series.highcharts-color-undefined.highcharts-series-0 {
	display: none;
}

g.highcharts-legend-item.highcharts-map-series.highcharts-color-undefined.highcharts-series-0 {
	display: none;
}

.ant-layout .ant-layout-header {
	background-color: #ffffff;
	box-shadow: 0px 4px 15px 0px #0000000D;
}

.ant-steps-item-active .ant-steps-item-title {
	color: var(--defaultThemeColor) !important;
}

/* ANIMATION */
.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 700ms ease-in;
	-webkit-transition: opacity 700ms ease-in;
	-moz-transition: opacity 700ms ease-in;
	-ms-transition: opacity 700ms ease-in;
	-o-transition: opacity 700ms ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 700ms ease-out;
	-webkit-transition: opacity 700ms ease-out;
	-moz-transition: opacity 700ms ease-out;
	-ms-transition: opacity 700ms ease-out;
	-o-transition: opacity 700ms ease-out;
}



.input__desc {
	background-color: var(--defaultwhitecolor);
	box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
	border: none;
	background: rgba(128, 128, 128, 0.14);
}

.date_Range {
	background-color: rgba(128, 128, 128, 0.14) !important;
	box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
	border: none;
	background: rgba(128, 128, 128, 0.14);
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}


.avtar {
	border: 1px solid var(--defaultThemeColor);
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
	font-style: italic;
	height: 41px;
	padding: 1.5rem;
	align-items: center;
	display: flex;
}

.toggling_sider {
	box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
	min-width: 250px !important;
}

.color_breadcrumb {
	color: var(--blackColor) !important;
	font-weight: 600 !important;
	margin-top: 15px;
}
.detail-card p {
	text-align: left;
}
.commonButton {
	background:var(--defaultThemeColor);
	border: none;
	color: #fff;
	font-size: 1rem;
	font-weight: 500;
	height: 2.5rem;
	min-width: 10rem;
}

.commonButtonProgram {
	background: rgb(255, 174, 0);
	border: none;
	color: #ffffff;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
}

.commonButtonDelete {
	background: #e34646;
	border: none;
	color: #fff;
	font-size: 1rem;
	font-weight: 600;
	height: 3rem;
	min-width: 6rem;
	text-transform: uppercase;
}

.commonButtonEdit {
	border: 2px solid var(--defaultThemeColor);
	color: #ffffff;
	font-size: 1rem;
	font-weight: 600;
	height: 3rem;
	min-width: 6rem;
	text-transform: uppercase;
	background: var(--defaultThemeColor);
}

.changePassword {
	color: #ffffff;
	font-size: 1rem;
	font-weight: 600;
	height: 3rem;
	min-width: 15rem;
	outline: auto;
	text-transform: uppercase;
	background: linear-gradient(to right, var(--defaultThemeColor), var(--darkBlueTheme));
	;
}

.contentHeader {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	/* align-items: center; */
}

.searchField>input {
	background-color: transparent !important;
	outline: none;
	border: none;
	padding-inline: 1rem;
}

.searchField {
	background: #ffffff !important;
	border: 1px solid #D3D3D3 !important;
	height: 2.5rem !important;
	min-height: 2.5rem !important;
}

.common-ico-btn{
	cursor: pointer;
	font-size: 1.2rem;
	color: white;
	padding: 6px;
	border-radius: 44px;
	height: 30px;
	width: 30px;
}

.edit_button {
	background: var(--defaultThemeColor);
}

.delete_button {
	background: #EE4741 !important;
}

.update_button{
	background: #7E92B6 !important;
}

.view_button {
	cursor: pointer;
	color: var(--defaultColorTwo);
	font-size: 1.3rem;
}

.commonBoxShadow {
	box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
}

.customSiderWidth {
	width: 250px !important;
	min-width: 250px !important;
	max-width: 250px !important;
	flex: 0 0 250px !important;
}

/* Dahboard animation */
.ag-courses_item {
	-ms-flex-preferred-size: calc(33.33333% - 30px);
	flex-basis: calc(33.33333% - 30px);
	margin: 0 15px 30px;
	overflow: hidden;
	border-radius: 10px;
	cursor: pointer;
}

.ag-courses-item_link {
	display: block;
	padding: 30px 20px;
	background-color: #fff;
	color: #000;
	overflow: hidden;
	position: relative;
}

.ant-image .ant-image-img {
	/* width: auto; */
	/* for sfari */
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
	text-decoration: none;
	color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
	-webkit-transform: scale(10);
	-ms-transform: scale(10);
	transform: scale(10);
}

.ag-courses-item_title {
	min-height: 60px;
	margin: 0 0 25px;
	overflow: hidden;
	font-weight: bold;
	font-size: 30px;
	color: #000;
	z-index: 2;
	position: relative;
	border-bottom: 1px solid #f0f0f0;
}

.ag-courses-item_date-box {
	font-size: 18px;
	color: #000;
	z-index: 2;
	position: relative;
}

.ag-courses-item_date {
	font-weight: 900;
	color: #000;
	-webkit-transition: color .5s ease;
	-o-transition: color .5s ease;
	transition: color .5s ease
}

.ag-courses-item_bg {
	height: 128px;
	width: 128px;
	background: linear-gradient(to right, var(--defaultThemeColor), var(--darkBlueTheme));
	z-index: 1;
	position: absolute;
	top: -75px;
	right: -75px;
	border-radius: 50%;
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}

.ant-layout-content {
	background: #ffffff !important;
}

.ant-modal .ant-modal-footer>.ant-btn+.ant-btn {
	background: var(--darkBlueTheme);
}

@media screen and (max-width: 992px) {
	form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
		width: 90%;
	}

	li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
		padding-left: 11px !important;
	}

	li.ant-menu-item.ant-menu-item-only-child {
		padding: 0 0 0 13px !important;
	}

	.toggling_sider {
		display: none;
	}

	.commonButton {
		min-width: 10rem !important;
	}

}

.ant-table-wrapper .ant-select-selector {
	display: none !important;
}

.stat-card {
	border-radius: 10px;
	background-color: #f9f9f9;
	padding: 20px;
}

.stat-item {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.stat-icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	margin-top: -1rem;
}

.stat-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.stat-text p {
	font-size: 12px;
	font-weight: bold;
	color: #333;
}

.stat-value {
	font-size: 20px !important;
	font-weight: bolder !important;
	color: #333;
}

.stat-date {
	border-top: 1px solid #ddd;
	padding-top: 10px;
	margin-top: 10px;
}

.stat-date span {
	color: #333;
	font-weight: normal;
	font-size: 10px;
}

.stat-date h6 {
	margin: 0;
	font-size: 12px;
	color: #333;
	font-weight: bolder;
}

.stat-detail-button {
	text-align: right;
	margin-top: 10px;
}

.stat-detail-button button {
	background-color: #8B0000;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
}

.ant-card-d .ant-card-body {
	padding: 15px;
}

.star-ratings .widget-svg {
	height: 30px !important;
	width: 30px !important;
}

.font-weight-bold {
	font-weight: 600;
	line-height: 1.4;
}

.jodit-wysiwyg p{
	text-align: left;
}